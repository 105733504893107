<template>
    <div>
        <h1>Office Settings</h1>
        <ChirpSettings v-if="!loading" :data_source="rows" :level="'office'" :level_id="level_id" />
    </div>
</template>

<script>
    const api_root = 'settings/list/office'

    import ChirpSettings from '@/components/general/list/ChirpSettings'

    export default {
        name: 'OfficeSettings',
        components: { ChirpSettings },
        data () {
            return {
                loading : 1,
                rows: [],
                level_id : 0,
            }
        },
        methods: {
            async init() {
                this.level_id = this.$store.state.office.id
                const res = await this.$api.get(`/${api_root}/${this.level_id}`)
                this.rows = res.data
                this.loading = 0
            },
        },
        async created() {
            await this.init()
        },
    }
</script>
